import { HttpClient } from "@angular/common/http";
import { computed, inject, Injectable, signal } from "@angular/core";
import { Router } from "@angular/router";
import { setUser } from "@sentry/angular";
import { Observable } from "rxjs";
import { map, tap } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { User } from "../models/user.model";
import { JwtService } from "./jwt.service";
@Injectable({ providedIn: 'root' })
export class UserService {

  private jwtService = inject(JwtService);
  private router = inject(Router);
  private httpClient = inject(HttpClient);

  public currentUser = signal<User | null>(null);
  public isAuthenticated = computed<boolean>(() => !this.isGuest() && !!this.currentUser());
  public isGuest = computed<boolean>(() => !this.currentUser()?.id);
  public userImage = computed<string>(() => this.currentUser()?.imageUrl ?? '/assets/icons/user.svg');

  login(email: string, password: string): Observable<{ token: string; user: User; pre2FAToken: string; }> {
    return this.httpClient.post<{ token: string; user: User; pre2FAToken: string; }>
      (`${environment.ukhabApiUrl}/auth/login`, { email, password });
  }

  verify2FA(pre2FAToken: string, token: string): Observable<User> {
    // Change the endpoint
    return this.httpClient.post<{ token: string; user: User; }>
      (`${environment.ukhabApiUrl}/auth/verify2FA`, { pre2FAToken, token })
      .pipe(
        tap(result => {
          const { token, user } = result;
          return this.setAuth(token, user)
        }),
        map(result => result?.user)
      );
  }

  recover2FA(pre2FAToken: string, recoveryCode: string): Observable<User> {
    // Change the endpoint
    return this.httpClient.post<{ token: string; user: User; }>
      (`${environment.ukhabApiUrl}/auth/recover2FA`, { pre2FAToken, recoveryCode })
      .pipe(
        tap(result => {
          const { token, user } = result;
          return this.setAuth(token, user)
        }),
        map(result => result?.user)
      );
  }

  logout(): void {
    this.purgeAuth();
    this.router.navigateByUrl('/login');
  }

  setAuth(token: string, user: User): void {
    this.jwtService.setToken(token);
    setUser({
      email: user.email,
      id: user.id?.toString() ?? 0,
      username: user.username
    });
    this.currentUser.set(user);
  }

  purgeAuth(): void {
    this.jwtService.destroyToken();
    setUser(null);
    this.currentUser.set(null);
  }
}
