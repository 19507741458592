import { computed, Injectable, signal } from "@angular/core";
import { UKHabDataLayer } from "./maps.service";

@Injectable({
  providedIn: 'root'
})
export class DataLayersService {
  public readonly DATA_LAYERS_SOURCES = ['ukhab-portal', 'ukhab-portal-v2'];

  readonly dataLayers = signal<UKHabDataLayer[]>([{
    id: 'lpa-uk',
    label: 'Local Planning Authorities UK',
    color: '#fff356',
    source: this.DATA_LAYERS_SOURCES[0],
    active: false,
  }, {
      id: 'nca-england',
      label: 'National Character Areas England',
      color: '#4A8FBD',
      source: this.DATA_LAYERS_SOURCES[1],
      active: false
    }, {
    id: 'sssi-england',
    label: 'SSSI England',
    color: '#f00',
    source: this.DATA_LAYERS_SOURCES[0],
    active: false
  }, {
    id: 'sssi-scotland',
    label: 'SSSI Scotland',
    color: '#0f0',
    source: this.DATA_LAYERS_SOURCES[0],
    active: false
  }, {
    id: 'sssi-wales',
    label: 'SSSI Wales',
    color: '#0000ff',
    source: this.DATA_LAYERS_SOURCES[0],
    active: false
  }, {
    id: 'aonb-england',
    label: 'AONB England',
    color: '#ff00ff',
    source: this.DATA_LAYERS_SOURCES[0],
    active: false
  }, {
    id: 'ramsar-england',
    label: 'Ramsar England',
    color: '#ff6600',
    source: this.DATA_LAYERS_SOURCES[0],
    active: false
  }, {
    id: 'ramsar-scotland',
    label: 'Ramsar Scotland',
    color: '#d16d8a',
    source: this.DATA_LAYERS_SOURCES[0],
    active: false
  }, {
    id: 'ramsar-wales',
    label: 'Ramsar Wales',
    color: '#ff007f',
    source: this.DATA_LAYERS_SOURCES[0],
    active: false
  }, {
    id: 'nnr-england',
    label: 'National Nature Reserves England',
    color: '#00ffff',
    source: this.DATA_LAYERS_SOURCES[0],
    active: false
  }, {
    id: 'nnr-scotland',
    label: 'National Nature Reserves Scotland',
    color: '#800080',
    source: this.DATA_LAYERS_SOURCES[0],
    active: false
  }, {
    id: 'nnr-wales',
    label: 'National Nature Reserves Wales',
    color: '#5b8c7e',
    source: this.DATA_LAYERS_SOURCES[0],
    active: false
  }, {
    id: 'lnr-england',
    label: 'Local Nature Reserves England',
    color: '#ffff99',
    source: this.DATA_LAYERS_SOURCES[0],
    active: false
  }, {
    id: 'sac-england',
    label: 'Special Areas of Conservation England',
    color: '#ffb3e6',
    source: this.DATA_LAYERS_SOURCES[0],
    active: false
  }, {
    id: 'sac-scotland',
    label: 'Special Areas of Conservation Scotland',
    color: '#b3b3ff',
    source: this.DATA_LAYERS_SOURCES[0],
    active: false
  }, {
    id: 'sac-wales',
    label: 'Special Areas of Conservation Wales',
    color: '#c2f0c2',
    source: this.DATA_LAYERS_SOURCES[0],
    active: false
  }, {
    id: 'spa-england',
    label: 'Special Protection Areas England',
    color: '#e6e6e6',
    source: this.DATA_LAYERS_SOURCES[0],
    active: false
  }, {
    id: 'spa-scotland',
    label: 'Special Protection Areas Scotland',
    color: '#f2c94c',
    source: this.DATA_LAYERS_SOURCES[0],
    active: false
  }, {
    id: 'spa-wales',
    label: 'Special Protection Areas Wales',
    color: '#c2c2f0',
    source: this.DATA_LAYERS_SOURCES[0],
    active: false
  }, {
    id: 'br-england',
    label: 'Biosphere Reserves England',
    color: '#f4a261',
    source: this.DATA_LAYERS_SOURCES[0],
    active: false
  }, {
    id: 'br-scotland',
    label: 'Biosphere Reserves Scotland',
    color: '#e76f51',
    source: this.DATA_LAYERS_SOURCES[0],
    active: false
  },
  {
    id: 'br-wales',
    label: 'Biosphere Reserves Wales',
    color: '#264653',
    source: this.DATA_LAYERS_SOURCES[1],
    active: false
  }]);

  layerIds = computed(() => this.dataLayers().map(a => a.id));
  activeIds = computed(() => this.dataLayers().filter(a => a.active).map(a => a.id));

  addToMap(map: mapboxgl.Map) {
    this.DATA_LAYERS_SOURCES.forEach(source => {
      map.addSource(source, {
        type: 'vector',
        url: `mapbox://natapp.${source}`
      });
    });

    for (const layer of this.dataLayers()) {
      map.addLayer(
        {
          'id': layer.id,
          'type': 'fill',
          'source': layer.source,
          'source-layer': layer.id,
          layout: {
            visibility: layer.active ? 'visible' : 'none'
          },
          paint: {
            'fill-opacity': 0.6,
            'fill-outline-color': '#000',
            'fill-color': layer.color
          }
        }
      );
    }
  }

  toggle(map: mapboxgl.Map, layer: UKHabDataLayer) {
    const active = !layer.active;
    const idx = this.dataLayers().findIndex(l => l.id === layer.id);
    if (idx === -1) {
      return;
    }
    this.dataLayers.update(layers => [
      ...layers.slice(0, idx),
      { ...layers[idx], active },
      ...layers.slice(idx + 1)
    ]);
    map.setLayoutProperty(layer.id, 'visibility', active ? 'visible' : 'none');
  }

  toggleAll(map: mapboxgl.Map, active: boolean) {
    const layers = this.dataLayers();
    let layersToToggle;
    if (active) {
      // toggle all layers on that aren't already
      layersToToggle = layers.filter(l => !l.active);
    } else {
      // toggle all layers off that aren't already
      layersToToggle = layers.filter(l => l.active);
    }
    layersToToggle.forEach(l => this.toggle(map, l));
  }
}
