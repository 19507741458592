import { Injectable } from "@angular/core";

export const daforScores = ['rare', 'occasional', 'frequent', 'abundant', 'dominant'] as const;

export type DaforScore = typeof daforScores[number];
export const dominScores = ['+', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'] as const;
export type DominScore = typeof dominScores[number];

export const dafor: Record<DaforScore, string> = {
  'rare': 'Rare (0.1 - 10%)',
  'occasional': 'Occasional (10 - 25%)',
  'frequent': 'Frequent (25 - 33%)',
  'abundant': 'Abundant (33 - 50%)',
  'dominant': 'Dominant (>50%)',
};

export const domin: Record<DominScore, string> = {
  '+': '+ (<1%)',
  '1': '1 (<1%)',
  '2': '2 (<1%)',
  '3': '3 (1-4%)',
  '4': '4 (5-10%)',
  '5': '5 (11-25%)',
  '6': '6 (26-33%)',
  '7': '7 (34-50%)',
  '8': '8 (51-75%)',
  '9': '9 (76-90%)',
  '10': '10 (91-100%)',
};


@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  getDafor(score: DaforScore) {
    return dafor[score];
  }
  getDomin(score: DominScore) {
    return domin[score];
  }

  downloadFile(data: BlobPart, filename: string, type: string) {
    const blob = new Blob([data], { type });
    const url = URL.createObjectURL(blob);
    return this.downloadFileUrl(url, filename);
  }

  downloadFileUrl(url: string, filename?: string) {
    const a = document.createElement('a');
    a.href = url;
    if (filename) {
      a.download = filename;
    }
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }
}
