import { Environment } from "./environment.model";

export const environment: Environment = {
  production: true,
  mapboxApiKey: 'pk.eyJ1IjoibmF0YXBwIiwiYSI6ImNqZHg1MXZndDNxM2UzM3FwdnRsdW96cWIifQ.szIwfw6q7QTADsLCQXCKiw',
  bingMapsApiKey: 'AlWr5u4AGzUgzhE-m2d3Zbpx0uVOu1M4c2Lfv1ACN7LM998xxx_35IKMlef7PC0A',
  coreoApiUrl: 'https://api.coreo.io',
  ukhabApiUrl: 'https://api.dev.ukhab.coreo.io',
  ukhabTierIds: [1, 2, 3, 6]
} as const;
